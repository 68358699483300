<template>
	<div>
		<base-table ref="table" :headers="headers" :items="fetchNotifications" :tbody-tr-class="tbodyTrClass" table-top paginate no-search>
			<template #action>
				<div class="d-flex flex-fill align-items-center">
					<v-select
						v-if="!inProject"
						v-model="projectId"
						class="flex-fill"
						clearable
						filterable
						searchable
						:options="projects"
						:reduce="(o) => o.id"
						placeholder="Select a project"
						style="width: 30%"
					/>
					<v-select
						v-model="type"
						class="flex-fill mx-75"
						clearable
						filterable
						searchable
						:options="types"
						:reduce="(o) => o.id"
						placeholder="Select a type"
						style="width: 30%"
					/>
					<b-form-group class="mb-0 mx-75 flex-fill">
						<b-input-group>
							<b-form-input v-model="date" type="text" autocomplete="off" show-decade-nav placeholder="Select a date" />
							<b-input-group-append>
								<b-form-datepicker v-model="date" show-decade-nav button-only right locale="en-US" @context="onContext" />
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div class="ml-1">
						<b-button variant="primary" @click="search">Search</b-button>
					</div>
				</div>
			</template>
			<template #cell(project_id)="data">
				<div class="d-flex ">
					<feather-icon v-if="data.item.read_at" icon="EyeIcon" size="18" class="mr-2 text-secondary" />
					<feather-icon v-else icon="CircleIcon" size="18" class="mr-2 text-primary" />
					<strong>{{ data.value }}</strong>
				</div>
			</template>
			<template #cell(created_at)="{ value }">
				<span>{{ getProperDate(value) }}</span>
			</template>
		</base-table>

		<confirm-dailog ref="confirm"></confirm-dailog>
	</div>
</template>

<script>
import { BButton, BFormGroup, BInputGroup, BInputGroupAppend, BFormDatepicker, BFormInput, VBTooltip } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import { getProperDate } from '@/utils/helpers';
import store from '@/store';
import vSelect from 'vue-select';
import ConfirmDailog from '@/components/ConfirmDailog.vue';

export default {
	name: 'Notifications',

	components: {
		BaseTable,
		BButton,
		BFormGroup,
		BInputGroup,
		BInputGroupAppend,
		BFormDatepicker,
		BFormInput,
		ConfirmDailog,
		vSelect
	},

	directives: {
		'b-tooltip': VBTooltip
	},

	data: () => ({
		headers: [{ key: 'project_id' }, { key: 'title' }, { key: 'message' }, { key: 'created_at', label: 'date' }],
		projectId: '',
		type: '',

		date: '',
		formatted: '',
		selected: '',

		projects: [],
		types: [
			{
				id: 1,
				label: 'Missing items reports'
			},
			{
				id: 2,
				label: 'Missing beneficiary reports'
			},
			{
				id: 3,
				label: 'Missing safeguard report'
			},
			{
				id: 4,
				label: 'An cccident occurred'
			},
			{
				id: 5,
				label: 'Form question alert'
			}
		]
	}),

	computed: {
		inProject() {
			return !!this.$route.params.id;
		}
	},

	async created() {
		if (this.inProject) {
			this.projectId = this.$route.params.id;
		}

		if (!this.inProject) {
			const data = await store.dispatch('projects/getProjectShortList');

			this.projects = data.map((p) => ({
				label: `${p.SP_TITLE} - ${p.SP_PROJID}`,
				id: p.SP_PROJID
			}));
		}
	},

	methods: {
		getProperDate,

		async fetchNotifications(ctx) {
			return store
				.dispatch('notifications/getNotifications', { ...ctx, project: this.projectId, date: this.date, type: this.type })
				.then((data) => {
					this.markAsRead();

					return data;
				});
		},

		markAsRead() {
			store.dispatch('notifications/readNotifications');
		},

		onContext(ctx) {
			this.formatted = ctx.selectedFormatted;
			this.selected = ctx.selectedYMD;
		},

		search() {
			this.$refs.table.refresh();
		},

		tbodyTrClass(item) {
			// eslint-disable-next-line no-nested-ternary
			return item?.read_at ? 'read' : 'not-read';
		}
	}
};
</script>

<style lang="scss">
[dir] .table th,
[dir] .table td {
	padding: 1.2rem 2rem;
}

.not-read:not(.b-table-details) {
	border-left: 10px solid #8bc34a;
}

.read:not(.b-table-details) {
	border-left: 10px solid #82868b;
}
</style>
